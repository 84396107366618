import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const BigTile = (props) => {
  return (
    <Stack
      backgroundColor="background.tiles"
      display="inline"
      minHeight="25vh"
      borderRadius={4}
      padding={4}
      boxShadow={`0 3px 24px rgb(0 0 0 / .02)`}
      spacing={3}
    >
      <Stack spacing={1}>
        <Box>
          <Typography variant="h3" color="primary">
            {props.name}
          </Typography>
          {props.price ? (
            <Typography variant="h6" fontWeight="bold" color="primary.light">
              {props.price}
            </Typography>
          ) : null}
        </Box>
        <Typography
          variant="h6"
          component="p"
          fontWeight={400}
          color="primary.light"
        >
          {props.description}
        </Typography>
      </Stack>
      {props.image ? (
        <>
          <Box
            sx={{
              maxHeight: "816px",
              overflow: "hidden",
            }}
          >
            <img src={props.image} alt={props.name} width="100%" />
          </Box>
        </>
      ) : null}
    </Stack>
  );
};

export default BigTile;
