import * as React from "react";
import { Helmet } from "react-helmet";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import icon from "../images/logo.png";
import MainPage from "../components/MainPage";
import staticData from "../components/utils/StaticData";

const IndexPage = () => {
  const data = staticData();
  const primary = data.website.live.styling?.primaryText;
  const header = data.website.live.styling?.header;
  const buttons = data.website.live.styling?.buttons;
  const footerBackground = data.website.live.styling?.footerBackground;
  const footerText = data.website.live.styling?.footerText;
  const background = data.website.live.styling?.background;
  const tilesBackground = data.website.live.styling?.tilesBackground;

  let theme = createTheme({
    spacing: 8,
    typography: {
      fontFamily: [
        "system-ui",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h1: {
        fontWeight: "Bold",
      },
      h2: {
        fontWeight: "Bold",
      },
      h3: {
        fontWeight: "Bold",
      },
      h4: {
        fontWeight: "Bold",
      },
      h5: {
        fontWeight: "Bold",
      },
      fontSize: 14,
    },
    palette: {
      primary: {
        main: primary || "#212121",
      },
      secondary: {
        main: buttons || "#212121",
      },
      header: {
        main: header || "#FAFAFA",
      },
      footer: {
        main: footerBackground || "#FAFAFA",
        contrastText: footerText || "#212121",
      },
      background: {
        default: background || "#FAFAFA",
        tiles: tilesBackground || "#FFFFFF",
      },
    },
  });

  theme = responsiveFontSizes(theme);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={data.brand.briefDescription} />
        <link rel="icon" type="image/png" href={icon} sizes="16x16" />
        <title>{data.brand.name}</title>
        {data?.website?.live?.meta?.googleAdsConversions?.leadConversionId && (
          <script
            async
            src={
              "https://www.googletagmanager.com/gtag/js?id=" +
              data.website.live.meta.googleAdsConversions.leadConversionId
            }
          ></script>
        )}
        {data?.website?.live?.meta?.googleAdsConversions?.leadConversionId && (
          <script type="text/javascript">
            {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments)}
                    gtag('js', new Date());

                    gtag('config','` +
              data.website.live.meta.googleAdsConversions.leadConversionId +
              `');
                    
                    function gtag_report_conversion(url) {
                            var callback = function () {
                            if (typeof(url) != 'undefined') {
                            window.location = url;
                        }
                    };
                        gtag('event', 'conversion', {
                        'send_to': '` +
              data.website.live.meta.googleAdsConversions.leadConversionId +
              "/" +
              data.website.live.meta.googleAdsConversions.leadLabel +
              `' ,
                        'event_callback': callback
                    });
                        return false;
                    }
                    `}
          </script>
        )}
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/*Do not know why default background does not work as expected */}
        <MainPage data={data}/>
      </ThemeProvider>
    </>
  );
};

export default IndexPage;
